import React, { useState, useEffect } from 'react';
import './App.css';

const App = () => {
  const [courses, setCourses] = useState([]);
  const [handicap, setHandicap] = useState('0');
  const [slope, setSlope] = useState(courses[0]?.slope || '');
  const [par, setPar] = useState(courses[0]?.par || '');
  const [rating, setRating] = useState(courses[0]?.rating || '');
  
  const [strokesGained, setStrokesGained] = useState('');
  const [colorScheme, setColorScheme] = useState('dark');
  
  useEffect(() => {
    // Make a fetch call or use axios to load the data from the JSON file
    fetch('./golfCourses.json')
      .then(response => response.json())
      .then(data => {
        setCourses(data.courses);
        setPar(data.courses[0].par);
        setSlope(data.courses[0].slope);
        setRating(data.courses[0].rating);
      });
  }, []);

  useEffect(() => {
    if(handicap){
        const calculatedStrokesGained = (handicap * slope) / 113 + (rating - par);
        const roundedStrokesGained = Math.round(calculatedStrokesGained);
        setStrokesGained(roundedStrokesGained);
    }
}, [handicap, slope, par, rating]);

  const handleCourseChange = (e) => {
    const selected = courses.find(course => course.name === e.target.value);
    setPar(selected.par);
    setSlope(selected.slope);
    setRating(selected.rating);
    const calculatedStrokesGained = (handicap * selected.slope) / 113 + (selected.rating - selected.par);
    const roundedStrokesGained = Math.round(calculatedStrokesGained);
    setStrokesGained(roundedStrokesGained);
  }

  // Create a function to switch between light and dark mode
  const toggleColorScheme = () => {
    if (colorScheme === 'light') {
      setColorScheme('dark');
    } else {
      setColorScheme('light');
    }
  };

  return (
    <html>
      <body>
        <div className='container' data-color-scheme={colorScheme}>
          <header className='header'>
            <div className='dark-light-scheme'></div>
            <h1 className='heading'> Hcp Calculator</h1>
            <label htmlFor="color-scheme" className='dark-light-scheme' onClick={toggleColorScheme}>
              {colorScheme === 'light' ? '🌙' : '🔅'}
            </label>
          </header>
          <div className='course_selector'>
            <select className={`${colorScheme === 'light' ? 'light-mode-input' : 'dark-mode-input'} course-selector-box`} onChange={handleCourseChange} id="course-selector-box" >
              {courses.map((course, index) => (
                <option key={index} value={course.name}>{course.name}</option>
              ))}
            </select>
          </div>
          <div className='main_container snaps-inline element'>
            <div className='HcpCalc'>
              <form className='input-form ' onClick={handleCourseChange}>
                <label htmlFor="handicap">Handicap:</label>
                <input className={`${colorScheme === 'light' ? 'light-mode-input' : 'dark-mode-input'} input-box`}
                  type="number"
                  step="0.1"
                  id="handicap"
                  value={handicap}
                  onChange={(e) => setHandicap(e.target.value)}
                />
                <br />
                <label htmlFor="slope">Slope:</label>
                <input className={`${colorScheme === 'light' ? 'light-mode-input' : 'dark-mode-input'} input-box`}
                  type="number"
                  id="slope"
                  value={slope}
                  onChange={(e) => setSlope(e.target.value)}
                />
                <br />
                <label htmlFor="par">Par:</label>
                <input className={`${colorScheme === 'light' ? 'light-mode-input' : 'dark-mode-input'} input-box`}
                  type="number"
                  id="par"
                  value={par}
                  onChange={(e) => setPar(e.target.value)}
                />
                <br />
                <label htmlFor="rating">Rating:</label>
                <input className={`${colorScheme === 'light' ? 'light-mode-input' : 'dark-mode-input'} input-box`}
                  type="number"
                  id="rating"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                />
                {strokesGained !== "" && (
                  <p>
                    <center>Your par:</center>
                    <div style={{ marginTop: '5px' }}>
                      <span className="fade-in" style={{ fontSize: '36px' }}>

                        {`${Number(strokesGained) + Number(par)} (${strokesGained > 0 ? '+' : ''}${strokesGained})`}

                      </span>
                    </div>
                  </p>
                )}
              </form>
            </div>

            <div className="handicap-table">

              <table>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Range</th>
                    <th style={{ textAlign: 'center' }}>Strokes</th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(20).keys()].map((sg) => {
                    let minHcp = null;
                    let maxHcp = null;
                    for (let hcp = 0; hcp <= 20; hcp += 0.1) {
                      const calculatedStrokesGained = (hcp * slope) / 113 + (rating - par);
                      const roundedStrokesGained = Math.round(calculatedStrokesGained);
                      if (roundedStrokesGained === sg) {
                        if (minHcp === null) {
                          minHcp = hcp;
                        }
                        maxHcp = hcp;
                      }
                    }
                    return (
                      <tr key={sg} style={{ backgroundColor: handicap >= minHcp && handicap <= maxHcp ? '#878d73' : 'inherit' }}>
                        <td style={{ textAlign: 'center' }}>{minHcp !== null && maxHcp !== null ? `${minHcp.toFixed(1)} - ${maxHcp.toFixed(1)}` : 'N/A'}</td>
                        <td style={{ textAlign: 'center' }}>{sg}</td>
                      </tr>
                    );
                  }
                  )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </body>
    </html >
  );
};

export default App;
